@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');


body {
  background: #ffffff;
  /* background-image: url('../src/img/bannerbg5.png'); */
  background-size: cover;
  /* font-family: "Gloria Hallelujah"; */
  font-family: "Short Stack", cursive;
  /* font-family: "Kalam", cursive; */
  /* font-family: "Pixelify Sans", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: url(../src/img/cursorretro.svg), auto; */
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

@keyframes slide {
  0% { transform: translateX(0); }
  50% { transform: translateX(-50%); }
}

.sliding-text {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  animation: slide 20s linear infinite;
}

.sliding-text:before {
  content: attr(data-text);
  flex-shrink: 0;
  white-space: nowrap;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}

.button-cartoon {
  border-bottom: 10px solid black;
}

.text-cartoon {
  text-shadow: 
  -1px -1px 0 #000,  
   1px -1px 0 #000,
  -1px  1px 0 #000,
   1px  1px 0 #000; /* Sombras en todas direcciones para crear el efecto de borde */
}

.glow {
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0% {
      box-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000;
  }
  50% {
    box-shadow: none;
  }
  100% {
      box-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000;
  }
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 68px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.meme-container {
  position: relative;
  text-align: center;
}

.meme-container img {
  width: 100%; /* Ajusta esto según necesites */
  height: auto; /* Ajusta esto para mantener la proporción de la imagen */
}

.meme-container .top,
.meme-container .bottom {
  position: absolute;
  width: 100%;
  padding: 32px;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 8px #000;
  overflow-wrap: break-word; 
  word-break: break-all; 
}

.meme-container .top {
  top: 0;
}

.meme-container .bottom {
  bottom: 0;
}

/* Estilos para el contenedor de la animación */
.slider-container {
  display: flex;
  width: 100vw;
  animation: scroll 15s linear infinite;
}

.slider-image {
  width: auto; /* Ajusta para mantener la proporción de la imagen */
  flex-shrink: 0; /* Evita que las imágenes se compriman */
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    /* Ajusta este valor según el ancho total de todas las imágenes duplicadas */
    transform: translateX(-300%);
  }
}